Vue.use(Router)

<template>

    <div>

        <v-row align="center" justify="center">
                <v-col cols ="12">

                  <v-card elevation="1" outlined>

                  <div class="mb-3 mt-3 d-flex justify-center" id="imgholder">
              

                   <v-snackbar
                                      timeout ="1000"
                                      v-model="snackbar.display"
                                      absolute
                                      outlined
                                      rounded="pill"
                                      color="primary"
                                      top
                                      >
                                      <v-icon class="mr-2" color="red">mdi-alert-circle-outline</v-icon>
                                      {{snackbar.displayText}}
                  </v-snackbar>
 

                  <v-img
                      v-bind:src="require('../assets/extragas-logo.jpeg')"
                      class="mx-2"
                      max-width="150"
                      contain
                    ></v-img>
                
                  </div>
              
                  <v-divider class="mb-4">insert</v-divider>
            

                  <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation >
                    <span class="form_labels">Email or Username:</span> 
                    <v-text-field
                          v-model = "username"
                          :rules  = "usernameRules"
                          label   = "Username:"
                          required
                          dense
                          solo
                    ></v-text-field>

                  <!-- for now lets not render (if 1==0) -->
                  <span v-if="1==0" class="form_labels">Password
                      <router-link to="/forgotPassword" class="ml-2 anchors">Forgot Password.</router-link>
                  </span> 

                    <v-text-field
                          :append-icon  = "showPassword  ? 'mdi-eye' : 'mdi-eye-off'"
                          :type         = "showPassword ?  'text'    : 'password'"
                          @click:append = "showPassword = !showPassword"
                          v-model       = "password"
                          :rules        = "passwordRules"
                          label         = "Password:"
                          required
                          dense
                          solo
                    ></v-text-field>
                
                    <v-container fluid>

                        <v-row class="justify-start" >

                             <v-btn color="primary" @click="handleSignon">Sign-on&nbsp;
                              <v-progress-circular  v-if  = "isSignonActive" size=18 right indeterminate></v-progress-circular>
                              <v-icon               v-else                      dark right>mdi-account-search</v-icon>
                             </v-btn>
 
                        </v-row>
                
                        <v-row class="justify-start" >
                       <!-- for now lets not render (if 1==0) -->
                         <span v-if="1==0" class="mt-2 form_labels" id="notMember">Not a member yet? 
                           <router-link to="/Signup" class="ml-2 anchors">Sign Up.</router-link>
                         </span>
                        </v-row>
            
                    </v-container>


                  </v-form>
                  </v-container>
              
                  <v-divider>
                
                  </v-divider>

                  <!-- for now lets not render (if 1==0) -->
                  <v-card-actions v-if="1==0" >
                    <v-spacer></v-spacer>
                      <v-btn 
                        @click="administratorClicked"
                        rounded 
                        color="success">
                        Administrator
                      </v-btn>
                  </v-card-actions>

                  </v-card>
                </v-col>
        </v-row>

    </div>

</template>

<script>
 
  import {store}        from '../main.js';

  export default {
        name        : 'Login',
        beforeDestroy  (){},
        destroyed      (){},
        created        (){ 
             this.resetLocalStorage();
        },
        computed    : {
            showReport(){
               return ( this.result.length > 1) ? true : false;
            },
            fullName(){
              return message => {
                 return `${message} ${this.firstName} ${this.lastName}`
              }
            }
        },
        data: () => ({
                isSignonActive : false,
                showPassword : false,
                snackbar     :  { "displayText" : "", "display" : false},
                valid        : true,
                username     : '',
                usernameRules: [
                  v => !!v || 'User Name is required',
                  v => (v && v.length <= 10) || 'User Name must be less than 10 characters',
                ],
              password: '',
              passwordRules: [
                v => !!v || 'Password is required',
//                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
              ],
              select: null,
              items: [
                'Item 1',
                'Item 2',
                'Item 3',
                'Item 4',
              ],
              checkbox: false,
              result:''
          }),

    methods: {
     
      showSnackbar( text ) {
                        this.snackbar.displayText = text;
                        this.snackbar.display     = true;
       },
       handleSignon(e){

                  e.preventDefault()
                 
                  try {
                        let self      = this;
                        let validated = this.$refs.form.validate()
                        if ( validated ){
                    
                            self.isSignonActive = true;
                            const url    = this.$restServer.loginURL;
                            this.$http.post(url,  {
                                                    sales_id:   this.username,
                                                    password:   this.password,
                                                  })
                            .then(  (response) => {
                                     self.isSignonActive = false;
                                     if ( response.data['RECORD_FOUND'] ) { 
                                             localStorage.setItem("equalResponseEngineer", JSON.stringify(response.data) );
                                             store.removeDatabase();
                                             store.setManageUsersPermission( response.data['MANAGE_USERS'] );
                                             store.setFormsAllowed( response.data['FORMS'] );
                                             self.$router.push('/engineer')
                               
                                    } else {
                                        var exception = (response.data['EXCEPTION']) ?  response.data['EXCEPTION'] : "record not found";
                                        throw `Sign-On Failed - ${exception}`; 
                                    }
  
                            })
                            .catch( (e) => {
                                   this.isSignonActive = false;
                                   this.showSnackbar(e)
                            });
                        }

                  } catch (e) {
                          this.isSignonActive = false;
                          this.showSnackbar(e)
                  }


      },
      administratorClicked() {
             this.$router.push('/administratorView')
      },
      validate () {
           this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset();
        this.result = '';
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }
    },
  }
</script>

<style scoped> 

  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');


  .form_labels {
    font-family: 'Source Sans Pro', sans-serif;
    font-size:    16px;
    font-weight:   700;
   }

  .anchors {
    text-decoration: none;
    font-weight:   400;
  }
</style>